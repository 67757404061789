<template>
    <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100" style="display: flex;justify-content: center;">
            <div class="md-layout-item  md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon">
                            <md-icon>ballot</md-icon>
                        </div>
                        <h4 class="title"> Método de pagamento</h4>
                    </md-card-header>
                    <md-content>
                        <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <md-radio v-model="methodPayment" value="credit" selected>Cartão de Crédito</md-radio>
                            <!-- <md-radio v-model="methodPayment" value="boleto" disabled>Boleto</md-radio> -->
                        </div>
                    </md-content>
                </md-card>
            </div>
        </div>
        <div v-if="this.methodPayment == 'credit'" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100" style="display: flex;justify-content: center;">
            <div class="md-layout-item  md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon">
                            <md-icon>payment</md-icon>
                        </div>
                        <h4 class="title"> Dados de pagamento</h4>
                    </md-card-header>
                    <div class="md-layout" :style="minHeightInfoPlan">
                        <div class="md-layout-item md-size-100">
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <md-field :class="[
                            {'md-valid': !errors.has('card_number') && touched.card_number},
                            {'md-error': errors.has('card_number')}]">
                                <label>Número do cartão</label>
                                <md-input v-model="card_number" data-vv-name="card_number" type="text" name="card_number"
                                    required v-validate="modelValidations.card_number">
                                </md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors.has('card_number')">close</md-icon>
                                </slide-y-down-transition>
                                <slide-y-down-transition>
                                    <md-icon class="success" v-show="!errors.has('card_number') && touched.card_number">done</md-icon>
                                </slide-y-down-transition>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <md-field :class="[
                            {'md-valid': !errors.has('card_holder_name') && touched.card_holder_name},
                            {'md-error': errors.has('card_holder_name')}]">
                                <label>Nome (como escrito no cartão)</label>
                                <md-input v-model="card_holder_name" data-vv-name="card_holder_name" type="text" name="card_holder_name"
                                    required v-validate="modelValidations.card_holder_name" style="text-transform:uppercase">
                                </md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors.has('card_holder_name')">close</md-icon>
                                </slide-y-down-transition>
                                <slide-y-down-transition>
                                    <md-icon class="success" v-show="!errors.has('card_holder_name') && touched.card_holder_name">done</md-icon>
                                </slide-y-down-transition>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <md-field :class="[
                            {'md-valid': !errors.has('card_expiration_month') && touched.card_expiration_month},
                            {'md-error': errors.has('card_expiration_month')}]">
                                <label>Mês de expiração</label>
                                <md-input v-model="card_expiration_month" data-vv-name="card_expiration_month" type="text"
                                    name="card_expiration_month" required v-validate="modelValidations.card_expiration_month">
                                </md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors.has('card_expiration_month')">close</md-icon>
                                </slide-y-down-transition>
                                <slide-y-down-transition>
                                    <md-icon class="success" v-show="!errors.has('card_expiration_month') && touched.card_expiration_month">done</md-icon>
                                </slide-y-down-transition>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <md-field :class="[
                            {'md-valid': !errors.has('card_expiration_year') && touched.card_expiration_year},
                            {'md-error': errors.has('card_expiration_year')}]">
                                <label>Ano de expiração</label>
                                <md-input v-model="card_expiration_year" data-vv-name="card_expiration_year" type="text"
                                    name="card_expiration_year" required v-validate="modelValidations.card_expiration_year">
                                </md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors.has('card_expiration_year')">close</md-icon>
                                </slide-y-down-transition>
                                <slide-y-down-transition>
                                    <md-icon class="success" v-show="!errors.has('card_expiration_year') && touched.card_expiration_year">done</md-icon>
                                </slide-y-down-transition>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <md-field :class="[
                            {'md-valid': !errors.has('card_cvv') && touched.card_cvv},
                            {'md-error': errors.has('card_cvv')}]">
                                <label>Código de segurança</label>
                                <md-input v-model="card_cvv" data-vv-name="card_cvv" type="text" name="card_cvv" required
                                    v-validate="modelValidations.card_cvv">
                                </md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors.has('card_cvv')">close</md-icon>
                                </slide-y-down-transition>
                                <slide-y-down-transition>
                                    <md-icon class="success" v-show="!errors.has('card_cvv') && touched.card_cvv">done</md-icon>
                                </slide-y-down-transition>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <md-field :class="[
                            {'md-valid': !errors.has('card_cpf_cnpj') && touched.card_cpf_cnpj},
                            {'md-error': errors.has('card_cpf_cnpj')}]">
                                <label>CPF ou CNPJ sem separadores</label>
                                <md-input v-model="card_cpf_cnpj" data-vv-name="card_cpf_cnpj" type="text" name="card_cpf_cnpj"
                                    required v-validate="modelValidations.card_cpf_cnpj">
                                </md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors.has('card_cpf_cnpj')">close</md-icon>
                                </slide-y-down-transition>
                                <slide-y-down-transition>
                                    <md-icon class="success" v-show="!errors.has('card_cpf_cnpj') && touched.card_cpf_cnpj">done</md-icon>
                                </slide-y-down-transition>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100" style="display: flex;justify-content: center;">
                            <div class="md-layout-item md-small-size-100 md-medium-size-25 md-large-size-25 md-xlarge-size-25">
                                <md-button class="md-success md-block" @click="getDataCard()"> Confirmar</md-button>
                            </div>
                            <div class="md-layout-item md-small-size-100 md-medium-size-25 md-large-size-25 md-xlarge-size-25">
                                <md-button class="md-danger md-block" @click="$router.go(-1)"> Voltar</md-button>
                            </div>
                        </div>

                        <div id="snackbar2"></div>
                    </div>
                </md-card>
            </div>
        </div>
        
        <div v-if="this.methodPayment == 'boleto'" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100" style="display: flex;justify-content: center;">
            <div class="md-layout-item  md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon">
                            <md-icon>receipt</md-icon>
                        </div>
                        <h4 class="title"> Boleto</h4>
                    </md-card-header>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-100">
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <md-field :class="[
                            {'md-valid': !errors.has('name') && touched.name},
                            {'md-error': errors.has('name')}]">
                                <label>Nome</label>
                                <md-input v-model="name" data-vv-name="name" type="text" name="name"
                                    required v-validate="modelValidations.name">
                                </md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors.has('name')">close</md-icon>
                                </slide-y-down-transition>
                                <slide-y-down-transition>
                                    <md-icon class="success" v-show="!errors.has('name') && touched.name">done</md-icon>
                                </slide-y-down-transition>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <md-field :class="[
                            {'md-valid': !errors.has('cpf') && touched.cpf},
                            {'md-error': errors.has('cpf')}]">
                                <label>CPF</label>
                                <md-input v-model="cpf" data-vv-name="cpf" type="text" name="cpf"
                                    required v-validate="modelValidations.cpf" style="text-transform:uppercase">
                                </md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors.has('cpf')">close</md-icon>
                                </slide-y-down-transition>
                                <slide-y-down-transition>
                                    <md-icon class="success" v-show="!errors.has('cpf') && touched.cpf">done</md-icon>
                                </slide-y-down-transition>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100" style="text-align:center">
                            <md-button class="md-success" @click="getDataCard()"> Gerar Boleto</md-button>
                        </div>

                        <div id="snackbar2"></div>
                    </div>
                </md-card>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        SlideYDownTransition
    } from 'vue2-transitions'
    import cardData from '../../../utils/events/finishCadCard.js'
    import services from '../../../routes/services.js'
    import models from '../../../routes/models.js'
    import axios from 'axios'
    import Swal from 'sweetalert2';
    const md5 = require('md5');
    export default {
        components: {
            SlideYDownTransition
        },
        data() {
            return {
                key: JSON.parse(localStorage.getItem('radio-default')).key,
                dataPlan: JSON.parse(sessionStorage.getItem('plans')),
                servicesPLan: JSON.parse(sessionStorage.getItem('services-plan')),
                minHeightInfoPlan: '',
                card_number: '',
                card_holder_name: '',
                card_expiration_month: '',
                card_expiration_year: '',
                card_cvv: '',
                card_cpf_cnpj: '',
                cloudURL: '',
                methodPayment: "credit",
                touched: {
                    card_number: false,
                    card_holder_name: false,
                    card_expiration_month: false,
                    card_nucard_expiration_yearmber: false,
                    card_cvv: false,
                    card_cpf_cnpj: false
                },

                modelValidations: {
                    card_number: {
                        required: true,
                        min: 16
                    },
                    card_holder_name: {
                        required: true,
                        min: 5
                    },
                    card_expiration_month: {
                        required: true,
                        min: 2
                    },
                    card_expiration_year: {
                        required: true,
                        min: 4
                    },
                    card_cvv: {
                        required: true,
                        min: 3
                    },
                    card_cpf_cnpj: {
                        required: true,
                        min: 11
                    }
                }
            }
        },
        methods: {
            getError(fieldName) {
                return this.errors.first(fieldName)
            },
            validate() {
                return this.$validator.validateAll().then(res => {
                    this.$emit('on-validated', res)
                    return res
                })
            },
            getDataCard() {
                let card = {}
                card.card_holder_name = this.card_holder_name
                card.card_expiration_date = this.card_expiration_month + '/' + this.card_expiration_year
                card.card_number = this.card_number
                card.card_cvv = this.card_cvv

                const cardValidations = pagarme.validate({
                    card: card
                })

                if (!cardValidations.card.card_number) {
                    let txt = "Número do cartão inválido."
                    this.getSnackBar(txt)
                }
                if (!cardValidations.card.card_holder_name) {
                    let txt = "Nome do portador inválido."
                    this.getSnackBar(txt)
                }
                if (!cardValidations.card.card_expiration_month) {
                    let txt = "Mês de expiração inválido."
                    this.getSnackBar(txt)
                }
                if (!cardValidations.card.card_expiration_year) {
                    let txt = "Ano de expiração inválido."
                    this.getSnackBar(txt)
                }
                if (!cardValidations.card.card_cvv) {
                    let txt = "Código de segurança inválido."
                    this.getSnackBar(txt)
                }

                const planId = JSON.parse(sessionStorage.getItem('plans')).id
                const str = this.card_expiration_year.substring(2, 4)
                const email = JSON.parse(localStorage.getItem('user')).email
                const customerName = JSON.parse(localStorage.getItem('user')).name + ' ' + JSON.parse(localStorage.getItem(
                    'user')).lastname
                const formData = {
                    api_key: 'ak_test_o6pNlMnzryy2DnrpqaIkG4iqy6ex5S',
                    plan_id: planId,
                    card_number: card.card_number,
                    card_holder_name: card.card_holder_name.toUpperCase(),
                    card_expiration_date: this.card_expiration_month + str,
                    card_cvv: card.card_cvv,
                    customer: {
                        name: customerName,
                        email: email,
                        // phone: {
                        //   number: JSON.parse(sessionStorage.getItem('user')).phone
                        // },
                        document_number: this.card_cpf_cnpj
                    }
                }

                Swal.fire({
                    title: "Aguarde",
                    html: "Estamos processando todas as informações...",
                    type: "warning",
                    onOpen: () => {
                        Swal.showLoading()
                        axios.post("https://api.pagar.me/1/subscriptions", formData).then((resp) => {
                            models().put("users/upgradePlan", {
                                "email": email,
                                "pagarmeId": resp.data.id,
                                "key": this.key,
                                "profile_id": 228,
                            }).then(() => {
                                Swal.fire({
                                    title: "Pronto!",
                                    html: `Seu plano foi atualizado com sucesso! Em breve você receberá uma confirmação no email registrado: <b>${JSON.parse(localStorage.getItem('user')).email}</b>`,
                                    type: "success"
                                })
                                localStorage.clear()
                                sessionStorage.clear()
                                this.$router.push('/login')
                            })
                        })
                    }
                })
            },
            getSnackBar(txt) {
                let x = document.getElementById("snackbar2");
                x.innerHTML = txt;
                x.className = "show";

                setTimeout(function () {
                    x.className = x.className.replace("show", "");
                }.bind(), 3000);
            }
        },
        mounted() {
            // this.minHeightInfoPlan = "min-height:" + document.getElementById("contentPlan").offsetHeight + "px"
        },
        watch: {
            methodPayment(newVal) {
                console.log(newVal)
            }
        }
    }

</script>
<style>
    .funcionalidades {
        margin: 0;
        padding: 0 0 0 0;
        margin-left: -13px;
    }

    #snackbar2 {
        visibility: hidden;
        /* Hidden by default. Visible on click */
        min-width: 250px;
        /* Set a default minimum width */
        margin-left: -125px;
        /* Divide value of min-width by 2 */
        background-color: #503;
        /* Black background color */
        color: #fff;
        /* White text color */
        text-align: center;
        /* Centered text */
        border-radius: 2px;
        /* Rounded borders */
        padding: 16px;
        /* Padding */
        position: fixed;
        /* Sit on top of the screen */
        z-index: 1;
        /* Add a z-index if needed */
        left: 50%;
        /* Center the snackbar */
        bottom: 30px;
        /* 30px from the bottom */
    }

    /* Show the snackbar when clicking on a button (class added with JavaScript) */

    #snackbar2.show {
        visibility: visible;
        /* Show the snackbar */
        /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
  However, delay the fade out process for 2.5 seconds */
        -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
    }

    /* Animations to fade the snackbar in and out */

    @-webkit-keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }

        to {
            bottom: 30px;
            opacity: 1;
        }
    }

    @keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }

        to {
            bottom: 30px;
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeout {
        from {
            bottom: 30px;
            opacity: 1;
        }

        to {
            bottom: 0;
            opacity: 0;
        }
    }

    @keyframes fadeout {
        from {
            bottom: 30px;
            opacity: 1;
        }

        to {
            bottom: 0;
            opacity: 0;
        }
    }

</style>
